import { IShellEvent } from './IShellEvent';
import { CommunicationChannels, ShellCommunicationEvents } from '@shared/generalInterfaces';
import { Injectable } from '@angular/core';
import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';
import { Router } from '@angular/router';
import { EventName } from '@itero/itero-client-communication/eventInfo';
import { CommunicationService } from "../../client-communication/communication.service";

@Injectable({ providedIn: 'root' })
export class BackButtonClickedEvent implements IShellEvent {

	constructor(private applicationNavigationService: ApplicationNavigationService,
				private router: Router,
				private communicationService: CommunicationService) {
	}

	get eventName(): string {
		return ShellCommunicationEvents.BackButtonClicked;
	}

	async handleCustomEvent(event: any, publishEventFunc: (eventName: string, targetApplications: Array<string>, eventPayload?: any) => Promise<void>) {
		const transitionsCount = this.applicationNavigationService.stackLength();
		if (transitionsCount === 1) {
			let transition = this.applicationNavigationService.peekNavigationTransition();
			this.applicationNavigationService.startClosingAppTimer(transition.transitionId);
			await publishEventFunc(ShellCommunicationEvents.PrepareToClose,
				[transition.navigatedToAppId]);
		} else if (transitionsCount > 1) {
			const transition = this.applicationNavigationService.popNavigationTransition();

			const url = new URL(transition.urlFrom);
			// In case if the navigation is within the same app and the MFE is full separate client application - launched in its own angular runtime 
			// or another from angular the routing inside the host could not affect MFE routing
			// in that case we need to send the event to the MFE to handle the navigation
			if (transition.navigatedFromAppId === transition.navigatedToAppId) {
				this.communicationService.publishEvent(CommunicationChannels.ApplicationChannel, 'app-shell', EventName.applyNavigationState, [transition.navigatedFromAppId],
					{
						navigateFromAppId: transition.navigatedToAppId,
						navigateToAppId: transition.navigatedFromAppId,
						urlTo: transition.urlFrom,
					});
			}
			this.router.navigateByUrl(url.pathname + url.search);
		} else {
			this.applicationNavigationService.navigateToHomePageWhenStackIsEmpty();
		}
	}

	handlePostMessage(event): void {}
}
