import { SimpleSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { Resource } from '@opentelemetry/resources';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { AppConfigService } from '../appConfig/appConfigService';
import { Injectable } from '@angular/core';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { TimberService } from '@logging/timber.service';

@Injectable({
	providedIn: 'root'
})
export class AppTelemetryService {
	constructor(private appConfigService: AppConfigService, private timberService: TimberService) {}

	async initialize(): Promise<void> {
		try {
			const resource = new Resource({
				'service.name': this.appConfigService.appSettings?.OpenTelemetryConfig?.Appname,
				'service.version': '1.0.0'
			});

			const provider = new WebTracerProvider({
				resource,
				spanProcessors: [
					new SimpleSpanProcessor(
						new OTLPTraceExporter({
							url: this.appConfigService.appSettings?.OpenTelemetryConfig?.Endpoint
						})
					),
				]
			});

			registerInstrumentations({
				instrumentations: [
					new UserInteractionInstrumentation(),
					new FetchInstrumentation({ propagateTraceHeaderCorsUrls: /.+/ }),
					new XMLHttpRequestInstrumentation({ propagateTraceHeaderCorsUrls: /.+/ }),
				],
				tracerProvider: provider,
			});

			provider.register({
				contextManager: new ZoneContextManager(),
				propagator: new W3CTraceContextPropagator()
			});
		} catch (error) {
			this.timberService.error('Error initializing telemetry', {
				error,
				module: 'AppTelemetryService'
			});
		}
	}
}