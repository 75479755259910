import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { TimberService } from '@logging/timber.service';

@Injectable()
export class AppUpdater {
	constructor(private swUpdate: SwUpdate, private timberService: TimberService) {}

	checkForUpdates(): void {
		if (this.swUpdate.isEnabled) {
			try {
				this.swUpdate.checkForUpdate().then(async (updateIsAvailable) => {
					if (updateIsAvailable) {
						this.timberService.info('A new updates is available for activation.', { module: 'AppUpdaterService' });
						this.swUpdate.activateUpdate().then((success) => {
							
							if (!success) {
								this.timberService.info('The client already on the latest version.', { module: 'AppUpdaterService' });
							} else {
								this.timberService.info('The app is updating...', { module: 'AppUpdaterService' });
								document.location.reload();
							}
						});
					} else {
						this.timberService.info('No new updates was found.', { module: 'AppUpdaterService' });
					}
				});
			} catch (err) {
				this.timberService.error('Error occurred.', { module: 'AppUpdaterService', error: err });
			}
		} else {
			this.timberService.info('Checking app new updates is disabled.', { module: 'AppUpdaterService' });
		}
	}
}
