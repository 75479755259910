import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { ReferralResponse, ActivateReferralResponse } from '../../shared/generalInterfaces';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { Observable, BehaviorSubject, Subscription, forkJoin, of, throwError, iif } from 'rxjs';

import { HttpParams } from '@angular/common/http';
import { DownloadFileService } from '../../shared/downloadFile.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { LabRxExtensionService } from '../labRxExtension/labRxExtension.service';
import { IRxUILabNote } from '../../interfaces/IEUPIteroNote';
import { ScreenshotToastService } from '@core/screenshotToast/screenshotToast.service';
import {
	AssetReferenceType,
	SoftwareOptionsForCompany,
	RowStatus,
	TreatmentStageEnum,
} from '@shared/enums';
import { SoftwareOptionsService } from '../../core/softwareOptions.service';
import { map, exhaustMap, tap, switchMap, take, catchError } from 'rxjs/operators';
import { PdfService } from 'app/services/pdf/pdf.service';
import { CompanyInfoModel } from 'app/practiceManagement/models/company-info.model';
import { EupDatePipe } from '@shared/eupDate.pipe';
import { AccountManagementService } from 'app/practiceManagement/services/account-management.service';
import { RxNotesParserService } from 'app/services/rx-notes-parser/rx-notes-parser.service';
import { RxNote } from 'app/services/rx-notes-parser/lib/notes.model';
import { IOrderInformation } from '@interfaces/IOrderInformation';

@Injectable()
export class RxService {
	private http: EupHttpHandler;

	IsSavingRx: boolean;
	IsOrderProceeding: boolean;
	HomeLinkClickEvent = new EventEmitter<any>();
	checkPatientExistByReq: Subscription;
	newNoteObservable$: BehaviorSubject<IRxUILabNote[]>;
	rxIdsForPrintObservable: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	iosimPlusScreenshotSuccess: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private globalSettings: GlobalSettingsService,
		private notificationService: NotificationService,
		http: EupHttpHandler,
		private translateService: TranslateService,
		private downloadFileService: DownloadFileService,
		public eupRoutesService: EupRoutesService,
		public labRxExtensionService: LabRxExtensionService,
		private screenshotToastService: ScreenshotToastService,
		private softwareOptionsService: SoftwareOptionsService,
		private pdfService: PdfService,
		private notesParserService: RxNotesParserService,
		private eupDatePipe: EupDatePipe,
		private accountManagementService: AccountManagementService
	) {
		this.http = http;
		this.handleNewNotes();
	}

	private handleNewNotes() {
		this.newNoteObservable$ = new BehaviorSubject([]);
	}

	shouldRxBeReadOnly(referral: ReferralResponse, isTakenByScanner): boolean {
		if (!referral) {
			return isTakenByScanner;
		}
		const companySoftwareOptions = this.globalSettings.get().companySoftwareOptions;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.referralWorkflowPractice,
			companySoftwareOptions
		);
		const { rowStatusId, isLoadedByScanningCenter } = referral;
		const isReferralRxShouldBeReadOnly = isReferralPractice && !(rowStatusId === RowStatus.Active && !isLoadedByScanningCenter);
		return isTakenByScanner || isReferralRxShouldBeReadOnly;
	}

	shouldBeAbleToDeleteRx(referral: ReferralResponse, isTakenByScanner): boolean {
		if (!referral) {
			return !isTakenByScanner;
		}
		const companySoftwareOptions = this.globalSettings.get().companySoftwareOptions;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.referralWorkflowPractice,
			companySoftwareOptions
		);
		const { rowStatusId } = referral;
		const isReferralCouldBeDeleted = !(isReferralPractice && rowStatusId === RowStatus.Resolved);
		return !isTakenByScanner && isReferralCouldBeDeleted;
	}

	private configuration(id: number, isLabOrTechnician: boolean, langCode: string): Observable<any> {
		let url = `${this.eupRoutesService.rx.configurationUrl}/${id}?isLabOrTechnician=${isLabOrTechnician}`;
		if (langCode) {
			url += `&LangCode=${langCode}`;
		}
		return this.http.get(url);
	}

	private getRxById(RxID: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getRxByIdUrl}/${RxID}`;
		return this.http.get(url);
	}

	public validateClinicRx(rxId: string ): Observable<boolean> {
		const url = `${this.eupRoutesService.rx.validateClinicRxUrl}/${rxId}`;
		return this.http.get(url);
	}

	public getBaseRx(rxId: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getBaseRxUrl}/${rxId}`;
		return this.http.get(url);
	}

	public setReadyForDownloadAsDownloadedForLab(orderId: number) {
		const url = this.eupRoutesService.orders.setReadyForDownloadAsDownloadedForLab;
		return this.http.post(url, { orderId: orderId }, undefined, true, false);
	}

	public processSendToLab(orderId: number) {
		const url = `${this.eupRoutesService.lab.processSendToLab}?orderHeaderId=${orderId}`;
		return this.http.get(url);
	}

	public getExportGalleryFile(orderId: string, patientName: string): void {
		const params = new HttpParams().set('orderId', orderId).set('assetReferenceType', AssetReferenceType.Fms_capture.toString());
		this.downloadFileService
			.getFile(this.eupRoutesService.orders.getScreenshotDownloadLink, { params }, patientName.replace(/\s/g, ''))
			.subscribe({
				error: (e) => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_screenshots_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				},
			});
	}

	public uploadScreenshotFile(orderId: string, captureObj: object): Observable<any> {
		const formData = new FormData();
		for (const [key, value] of Object.entries(captureObj)) {
			formData.append(key, value);
		}
		return this.http.post(this.eupRoutesService.orders.uploadCapture + `?orderId=${orderId}`, formData, {}, false, false)
		.pipe(
			map(result => {
				if(result.isFileUploadedSuccessfully) {
					this.uploadCaptureFileSuccess(orderId);
					return result;
				} else {
					this.uploadCaptureFileError();
					return of(null);
				}
			}),
			catchError((error) => {
				this.uploadCaptureFileError();
				return of(error);
			}),
		);
	}

	public uploadCaptureFile(orderId: string, captureObj: object) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			for (const [key, value] of Object.entries(captureObj)) {
				formData.append(key, value);
			}

			this.http.post(this.eupRoutesService.orders.uploadCapture + `?orderId=${orderId}`, formData, {}, false, false).subscribe({
				next: (result) => {
					if (result.isFileUploadedSuccessfully) {
						this.uploadCaptureFileSuccess(orderId);
						resolve(result);
					} else {
						this.uploadCaptureFileError();
						reject(result);
					}
				},
				error: (reason) => {
					this.uploadCaptureFileError();
					reject(reason);
				},
			});
		});
	}

	public uploadCaptureIOSimFile(orderId: string, captureObj: object): Observable<boolean> {
		const formData = new FormData();
		for (const [key, value] of Object.entries(captureObj)) {
			formData.append(key, value);
		}

		const success$ = of(true).pipe(tap(() => this.uploadCaptureFileSuccess(orderId)));

		const error$ = of(false).pipe(
			tap(() => this.uploadCaptureFileError()),
			switchMap((err) => throwError(err))
		);

		return this.http
			.post(this.eupRoutesService.orders.uploadIOSimCapture + `?orderId=${orderId}`, formData, {}, false, false)
			.pipe(switchMap(({ isFileUploadedSuccessfully }) => iif(() => isFileUploadedSuccessfully, success$, error$)));
	}

	private uploadCaptureFileSuccess(orderId: string): void {
		this.iosimPlusScreenshotSuccess.next(orderId);
		this.screenshotToastService.show(this.translateService.instant('Orders.Capture_Saved'));
	}

	private uploadCaptureFileError(): void {
		this.notificationService.show(
			this.translateService.instant('Errors.Error_download_file_title'),
			this.translateService.instant('Orders.Capture_Upload_Failed'),
			{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
		);
	}

	public printOrdersRx(orderIds: string): Observable<string> {
		const url = `${this.eupRoutesService.orders.updatePrintedOrdersUrl}`;
		const printOrdersPost = this.http.post(url, { orderIds: orderIds }, undefined, true, false);
		const rxIdsForPrint = orderIds === '' ? [] : orderIds.split(',')?.map((rxId) => parseInt(rxId));
		this.rxIdsForPrintObservable.next(rxIdsForPrint);

		printOrdersPost.pipe(take(1)).subscribe();

		return this.rxIdsForPrintObservable.asObservable().pipe(take(1), map((rxIds) => rxIds.join(',')));
	}

	setOrderInformation(orderInformation: IOrderInformation): void {
		this.labRxExtensionService.initOrderInformation(orderInformation);
	}

	canOrderPhysicalModel(companyId: number): Observable<boolean> {
		const route = `${this.eupRoutesService.orderInformation.canOrderPhysicalModel}${companyId}`;
		return this.http.get(route, null, false, true).pipe(map((response) => response.Result));
	}

	private generateReferralCode(rxId: string, companyId: number): Observable<ActivateReferralResponse> {
		const url = this.eupRoutesService.rxForm.activateReferral;
		return this.http.post(url, { id: rxId, companyId }, false, false);
	}

	private getCompanyInfo(companyId: number): Observable<CompanyInfoModel> {
		return this.accountManagementService.getCompanyInfo(companyId);
	}

	private getBuiltAddress(companyInfo: CompanyInfoModel) {
		return this.accountManagementService.buildFullAddress(
			companyInfo.addressStreet1,
			companyInfo.addressStreet2,
			companyInfo.addressStreet3,
			companyInfo.city,
			companyInfo.stateCode,
			companyInfo.postalCode,
			companyInfo.countryCode
		);
	}

	private async generateReferralPdf(rx: any, referral: ReferralResponse, companyInfo: CompanyInfoModel, caseType: string, notes: RxNote[]) {
		const formattedDueDate = this.eupDatePipe.transform(rx.Order.DueDate, null);

		const REFERRAL_EXPIRATION_DAYS = 60;
		const issueDate = moment(referral.expiredDate).add(-REFERRAL_EXPIRATION_DAYS, 'days').format();
		const formattedIssueDate = this.eupDatePipe.transform(issueDate, null);
		const address = this.getBuiltAddress(companyInfo);
		const treatmentStage = rx.TreatmentStage ? rx.TreatmentStage.replace(/([a-z])([A-Z])/g, '$1 $2') : '';
		const formattedReferralCode = referral.referralCode
			.replace(/-/g, '')
			.replace(/^(?=[0-9]{10})([0-9]{3})([0-9]{3})([0-9]{4})$/, '$1-$2-$3');

		const referralParams = {
			caseType,
			notes,
			doctorName: rx.Doctor.Name,
			dueDate: formattedDueDate,
			issueDate: formattedIssueDate,
			practiceAddress: address,
			practicePhone: companyInfo.phone,
			practiceName: companyInfo.businessPartnerName,
			patientName: rx.Patient.FullName,
			referralCode: formattedReferralCode,
			treatmentStage: treatmentStage,
		};

		const referralPdf = await this.pdfService.createReferralPdf(referralParams);
		return referralPdf;
	}

	public getRxReferralAndDownloadPdf(rxObj: any) {
		return this.getRxById(rxObj.ID || rxObj).pipe(
			exhaustMap((rxResponse) => {
				const rx = rxResponse.Result;
				rx.TreatmentStage = TreatmentStageEnum[rx.TreatmentStage];
				const companyId = rx.CompanyID;
				const referralResponse$ = this.generateReferralCode(rx.ID, companyId);
				const companyInfo$ = this.getCompanyInfo(companyId);
				const { selectedLanguage } = this.globalSettings.get();
				const configuration$ = this.configuration(companyId, false, selectedLanguage.code);

				return forkJoin({
					rx: of(rx),
					referralResponse: referralResponse$,
					companyInfo: companyInfo$,
					configuration: configuration$,
				});
			}),
			tap(async ({ rx, referralResponse, companyInfo, configuration }) => {
				const caseType = configuration.Result.CaseTypes.find((type) => type.Id === rx.Order.CaseTypeId);
				const { referral } = referralResponse;
				const fileName = `Referral-${referral.referralCode}`;
				const formattedNotes = this.notesParserService.parse(rx.Notes);
				const referralPdf = await this.generateReferralPdf(rx, referral, companyInfo, caseType?.Name, formattedNotes);

				referralPdf.download(fileName);
			}),
			map(({ referralResponse }) => {
				const { referral, referralStatus } = referralResponse;
				return { ...referral, referralStatus };
			})
		);
	}
}
