import {Injectable} from '@angular/core';
import {RxService} from '../../../rx/services/rx.service';
import {IRxUILabNote} from '../../../interfaces/IEUPIteroNote';
import {BehaviorSubject} from 'rxjs';
import {GlobalSettingsService} from '../../../core/globalSettings.service';
import { RxAppService } from 'app/rx/services/rx-app.service';

@Injectable({
	providedIn: 'root'
})
export class OrderNotesService {
	public newNote$: BehaviorSubject<IRxUILabNote[]>;

	constructor(private rxService: RxService, private globalSettingService: GlobalSettingsService,
		 private rxAppService: RxAppService) {
		this.initLabNotesService();
	}

	private initLabNotesService(): void {
		this.newNote$ = this.rxService.newNoteObservable$;
	}

	public addLabRxNote(noteBody: string): any {
		const loggedUser = this.globalSettingService.get().username;
		this.rxAppService.addNote({ content: noteBody, createdByName: loggedUser });
	}
}
