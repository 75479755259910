import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ContextParams, GlobalSettingsService } from './core/globalSettings.service';
import { RouterInterceptorService } from './core/routerInterceptor.service';
import { PlatformDetectorService } from './core/platformDetector.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { QueryStringParams } from 'assets/consts';
import { EupHttpHandler } from './core/eupHttpHandler.service';
import { EupRoutesService } from './core/eupRoutes.service';
import { AppearanceService } from './core/appearance.service';
import { Appearance, FeatureToggle } from './shared/enums';
import { Observable, Subscription, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
import { FileInfo } from './shared/downloadNotification/fileInfo.model';
import { SettingsService } from '@shared/settings.service';
import { Utils } from '@shared/utils.service';
import { environment } from 'environments/environment';
import { AppConfigService } from './services/appConfig/appConfigService';
import { FeaturesToggleSettingsService } from './featuresToggleSettings/service/featuresToggleSettings.service';
import { SplunkRum } from '@splunk/otel-web';
import { AuthService } from '../app/services/authentication/auth.service';
import { SessionExpiredEventInfo } from './shared/generalInterfaces';
import { LogoutParameters } from './services/authentication/models/logout-parameters';
import { ShellContextService } from './services/shell-context/shell-context.service';
import { ShellCommunicationService } from './services/shell-communication/shell-communication.service';
import { Consts } from '@shared/consts';
import { FeatureToggleSettings } from './featuresToggleSettings/featuresToggleSettings.model';
import { LoggingInitializerService } from '@logging/logging-initializer.service';
import { PlatformCommunicationService } from './platform/services/platform-communication/platform-communication.service';
import { PendoService } from '@shared/pendo/pendo.service';
import { SpinnerService } from '@core/spinner/spinner.service';
import { AppTelemetryService } from './services/appTelemetry/apptelemetry.service';

export class AppConfig {}

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	browserLang = this.translateService.getBrowserLang();
	browserCultureLang = this.translateService.getBrowserCultureLang();
	isScanner: boolean;
	isProduction: boolean;
	ftSubscription: Subscription;
	isSpinnerActive = false;
	private files$: Observable<FileInfo[]>;
	private spinnerSubscription: Subscription;

	@HostBinding('lang') lang = 'en-US';
	@HostBinding('class.light-theme') lightThemeClass = false;

	@HostBinding('class.windows') windowsClass: boolean;
	@HostBinding('class.ie') ieClass: boolean;
	@HostBinding('class.edge') edgeClass: boolean;
	@HostBinding('class.is-tablet') isTabletClass: boolean;
	@HostBinding('class.iPad') iPadClass: boolean;
	@HostBinding('class.mac') macClass: boolean;

	constructor(
		private http: EupHttpHandler,
		private translateService: TranslateService,
		private globalSettingsService: GlobalSettingsService,
		private routerInterceptor: RouterInterceptorService,
		private platformDetector: PlatformDetectorService,
		private appearanceService: AppearanceService,
		private eupRoutesService: EupRoutesService,
		private settingsService: SettingsService,
		private downloadNotificationService: DownloadNotificationService,
		private utils: Utils,
		private appConfigService: AppConfigService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private authService: AuthService,
		private renderer: Renderer2,
		private shellContextService: ShellContextService,
		private shellCommunicationService: ShellCommunicationService,
		private loggingInitializerService: LoggingInitializerService,
		private platformCommunicationService: PlatformCommunicationService,
		private pendoSerivce: PendoService,
		private spinnerService: SpinnerService,
		private appTelemetry: AppTelemetryService
	) {
		this.spinnerSubscription = this.spinnerService.onActiveChanged.subscribe((isActive: boolean) => {
			this.isSpinnerActive = isActive;
		});
	}

	ngOnDestroy(): void {
		window.removeAllListeners('midc_event_onTokenExpired');
		this.shellCommunicationService.removeEventListeners(window);
		this.downloadNotificationService.clear();
		this.utils.clearObservablesSubscriptions(this);
	}

	ngOnInit() {
		this.loggingInitializerService.initialize();
		this.platformCommunicationService.subscribeToPlatformChannel();
		this.platformCommunicationService.subscribeToApplicationChannel();
		this.shellCommunicationService.subscribeToPlatformChannel();
		this.shellCommunicationService.subscribeToApplicationChannel();
		window.addEventListener('midc_event_onTokenExpired', this.handleTokenExpired.bind(this));
		this.shellCommunicationService.initEventListeners(window);
		if (environment.production) {
			console.log = () => {};
		}
		this.routerInterceptor.initialize();
		this.platformDetector.initialize();

		this.windowsClass = this.platformDetector.isWindows;
		this.ieClass = this.platformDetector.isIE;
		this.edgeClass = this.platformDetector.isEdge;
		this.isTabletClass = this.platformDetector.isTablet;
		this.iPadClass = this.platformDetector.isiPad;
		this.macClass = this.platformDetector.isMac;

		this.isScanner = this.utils.isScanner();
		this.translateService.setDefaultLang('en-US');
		if (!this.isSetLanguageFromUrl()) {
			this.setLanguage();
		}

		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			if (event.lang) {
				this.lang = event.lang;
			}
		});

		this.appearanceService.appearanceChanged$.subscribe((theme) => {
			this.lightThemeClass = theme === Appearance.Light;
		});

		this.appearanceService.responsiveChanged$.subscribe((isResponsive) => {
			if (isResponsive) {
				this.renderer.addClass(document.body, 'responsive');
			} else {
				this.renderer.removeClass(document.body, 'responsive');
			}
		});

		this.ftSubscription = this.featuresToggleSettingsService
			.getAll()
			.pipe(
				tap((res: FeatureToggleSettings[]) => {
					const subscribeExportFilesOnlyOnce =
						res.find((f) => f.id === FeatureToggle.SubscribeExportFilesOnlyOnce)?.isActive === true;
					if (subscribeExportFilesOnlyOnce) {
						this.files$ = this.downloadNotificationService.getFiles();
					} else {
						this.files$ = this.downloadNotificationService.getObservable();
					}

					const splunkRumFeature = res.find((f) => f.id === FeatureToggle.SplunkRum)?.isActive === true;
					if (!splunkRumFeature && SplunkRum.inited) {
						SplunkRum.deinit();
					}
					if (splunkRumFeature && !SplunkRum.inited) {
						SplunkRum.init({
							beaconUrl: this.appConfigService.appSettings.signalFx.beaconUrl,
							rumAuth: this.appConfigService.appSettings.signalFx.rumAuth,
							app: this.appConfigService.appSettings.signalFx.app,
							environment: this.appConfigService.appSettings.signalFx.environment,
						});
					}
					const appTelemetryFeature = res.find((f) => f.id === FeatureToggle.OpenTelemetryFeatureFlag)?.isActive === true;
					if (appTelemetryFeature) {
						this.appTelemetry.initialize();
					}
				})
			)
			.subscribe();

			const globalSettings = this.globalSettingsService.get();
			if (globalSettings?.contactId && globalSettings?.selectedCompanyId) {
				this.pendoSerivce.initializePendo(globalSettings.contactId, globalSettings.selectedCompanyId);
			}

			this.globalSettingsService.contextChanged
				.pipe(
					tap((params: ContextParams) => {
						this.pendoSerivce.initializePendo(params.doctorId, params.companyId);
					})
				)
				.subscribe();
	}

	isSetLanguageFromUrl(): boolean {
		const language = this.utils.getUrlParameter(QueryStringParams.LANGUAGE);
		if (language) {
			this.setLang(this.utils.getValidLanguage(language));
			return true;
		}
		return false;
	}

	private handleTokenExpired(event: any): void {
		const sessionInfo = localStorage.getItem(Consts.Storage.SessionInfo);
		if (sessionInfo) {
			const eventDetail = (<CustomEvent>event).detail as SessionExpiredEventInfo;
			if (eventDetail.sessionId) {
				const logoutParams = this.getLogoutParams(eventDetail);
				this.authService.logout(eventDetail.sessionId, logoutParams).subscribe();
			}
		}
	}

	private setLanguage(): void {
		const globalSettings = this.globalSettingsService.get();
		// try to set the language according to the selected language of the logged in user
		if (globalSettings && globalSettings.selectedLanguage) {
			this.setLang(globalSettings.selectedLanguage.code);
			this.eupRoutesService.doAfterInit(() => of({}), true).subscribe();
		} else {
			// in order to detect browser language for ie & edge needs http request
			if (this.platformDetector.isIE || this.platformDetector.isEdge) {
				this.setLang('en-US');
				const getLangCode = () => this.http.get(this.eupRoutesService.login.browserLanguageCode, undefined, false, false);
				this.eupRoutesService.doAfterInit(getLangCode, true).subscribe(
					(res: any) => {
						this.setLang(res.detectedCulture);
					},
					() => {
						this.setLang('en-US');
					}
				);
			} else {
				this.settingsService
					.getLocalizationSettings()
					.pipe(take(1))
					.subscribe((res: any) => {
						const supportedLangs = res.languages.map((i) => i.code);
						if (this.browserCultureLang && supportedLangs.indexOf(this.browserCultureLang) > -1) {
							this.setLang(this.browserCultureLang);
						} else {
							if (this.browserLang && supportedLangs.filter((sl) => sl.substring(0, 2) === this.browserLang)[0]) {
								this.setLang(supportedLangs.filter((sl) => sl.substring(0, 2) === this.browserLang)[0]);
							}
						}
					});
			}
		}
	}

	private setLang(lang: string): void {
		this.lang = lang;
		if (!lang) {
			const globalSettings = this.globalSettingsService.get();
			if (globalSettings && globalSettings.selectedLanguage) {
				this.lang = globalSettings.selectedLanguage.code;
			}
		}

		this.translateService.use(this.lang);
		this.shellContextService.updateContext((ctx) => {
			ctx.UI.language = this.lang;
		});
	}
	private getLogoutParams(event: SessionExpiredEventInfo): LogoutParameters {
		const logoutParams: LogoutParameters = {};
		if (event.payload?.returnUrl) {
			logoutParams.returnUrl = event.payload.returnUrl;
		}
		return logoutParams;
	}
}

export class BrowserInfo {
	detectedCulture: string;
}
