export class KnownComponentsNames {
	public static readonly WebViewer: string = 'webViewer';
	public static readonly PastOrdersComponent: string = 'pastOrders';
	public static readonly PatientOrdersComponent: string = 'patientOrders';
	public static readonly AohsComponent: string = 'itero-aohs';
	public static readonly OrdersPage: string = 'orders-page';
	public static readonly IosimPlus: string = 'iosimplus';
	public static readonly ImageHub = 'image-hub';
	public static readonly PendoSdk = 'pendo-sdk';
	public static readonly AccountManagementAppId = 'account-management-ui';
	public static readonly AccountManagement = 'practice-management';
	public static readonly UnknownComponent: string = 'unknown';
}
