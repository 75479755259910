export class Consts {
	public static ShellStoreName = 'ShellStore';
	public static AccountManagementAppId = 'account-management-ui';
	public static AccountManagement = 'practice-management';
	public static wrapper = 'wrapper';

	public static Storage = {
		Settings: 'settings',
		Routes: 'routes',
		RegionalApiEndpoint: 'regionalApiEndpoint',
		ServerVersion: 'serverVersion',
		SessionInfo: 'sessionInfo',
		TransitionsStack: 'transitionsStack',
		Logo: 'logo'
	};

	public static TablesIds = {
		PracticeUsers: 'practice-users-table',
		PracticeInvitations: 'practice-invitations-table'
	};

	public static GoogleApiAddressTypes = {
		Locality: 'locality',
		PostalCode: 'postal_code',
		Country: 'country',
		AdministrativeAreaLevel1: 'administrative_area_level_1'
	};

	public static HeaderKeys = {
		SelectedDoctorId: 'X-Doctor-ID',
		SelectedCompanyId: 'X-Company-ID',
		Authorization: 'Authorization',
		LogonAsId: 'X-LogonAs-ID',
	};

	public static SessionType = 'web';

	public static Resolution = {
		Mobile: 768
	};

	public static Enablers = {
		isCaseFlowManagementEnabled: 'isCaseFlowManagementEnabled',
		isShare3DPreviewEnabled: 'isShare3DPreviewEnabled'
	};

	public static Context = {
		CaseFlowManagement: 'CaseFlowManagement',
		Share3DPreview: 'Share3DPreview'
	};

	public static RefreshTokenPath = 'session/refresh?sessionId={0}&appId={1}';
	public static LogoutPath = 'authorize/logout';
	
	public static NavigationCommands = new Map<string, any[]>([
			['iosim', ['/', 'doctors', 'iosim-plus']]
	]);
}
